Ext.define('Admin.view.locations.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'adminlocations',
    xtype: 'adminlocation',
    title: 'Location Management',
    url: 'locations_path',
    form: 'adminlocationform',
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 200
        },
        {
            dataIndex: 'postalcode',
            text: 'Postal Code'
        },
        {
            dataIndex: 'address',
            text: 'Address',
            width: 300
        }
    ]
})