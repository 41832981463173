window.STOCK_HIDDEN_COLUMNS = {
    slot: [
        {
            dataIndex: 'machine_name',
            text: 'Machine Name',
            width: 150
        },
        {
            dataIndex: 'location_name',
            text: 'Location',
            width: 250
        },
        {
            dataIndex: 'slot_id',
            text: 'Slot',
            width: 60,
            align: 'center',
            renderer: function(value){
                return parseInt(value) - 1;
            }
        },
        {
            dataIndex: 'status',
            text: 'Status',
            width: 100,
            align: 'center',
            renderer: function(value){
                console.log(value)
                return `<span class="x-fa fa-${value ? 'times' : 'check'} x-color-${value ? 'red' : 'green'}"></span>`
            }
        },
        {
            dataIndex: 'product_name',
            text: 'Product Name',
            width: 200,
            sortable: false
        },
        {
            dataIndex: 'max_qty',
            width: 100,
            text: 'Max QTY',
        },
        {
            dataIndex: 'current_slot_qty',
            text: 'Current QTY',
            align: 'center',
            sortable: false,
        },
        {
            dataIndex: 'max_qty',
            width: 100,
            text: 'Empty QTY',
            renderer: function(value,meta, record){
                return value - record.get('current_slot_qty')
            }
        },
        {
            dataIndex: 'zone_name',
            text: 'Zone',
            align: 'center',
            sortable: false,
        },
        {
            dataIndex: 'generated_at',
            text: 'Last Updated On',
            align: 'center',
            xtype: 'datecolumn',
            format: 'd-m-Y h:i A',
            width: 150
        }
    ],
    product: [
        {
            dataIndex: 'machine_name',
            text: 'Machine Name',
            width: 150
        },
        {
            dataIndex: 'location_name',
            text: 'Location',
            width: 250
        },
        {
            dataIndex: 'product_name',
            text: 'Product Name',
            width: 200,
            sortable: false
        },
        {
            dataIndex: 'max_qty',
            width: 100,
            text: 'Max QTY',
        },
        {
            dataIndex: 'current_product_qty',
            text: 'Current QTY',
            align: 'center',
            sortable: false,
        },
        {
            dataIndex: 'generated_at',
            text: 'Last Updated On',
            align: 'center',
            xtype: 'datecolumn',
            format: 'd-m-Y h:i A',
            width: 150
        }
    ],
    machine: [
        {
            dataIndex: 'machine_name',
            text: 'Machine Name',
            width: 150
        },
        {
            dataIndex: 'location_name',
            text: 'Location',
            width: 250
        },
        {
            dataIndex: 'max_qty',
            width: 100,
            text: 'Max QTY',
        },
        {
            dataIndex: 'current_machine_qty',
            text: 'Current QTY',
            align: 'center',
            sortable: false,
        },
        {
            dataIndex: 'max_qty',
            width: 100,
            text: 'Empty QTY',
            renderer: function(value,meta, record){
                return value - record.get('current_machine_qty')
            }
        },
        {
            dataIndex: 'zone_name',
            text: 'Zone',
            align: 'center',
            sortable: false,
        },
        {
            dataIndex: 'generated_at',
            text: 'Last Updated On',
            align: 'center',
            xtype: 'datecolumn',
            format: 'd-m-Y h:i A',
            width: 150
        }
    ]
}
const channels = require.context('.', true, /\.js$/)
channels.keys().forEach(channels)