Ext.define('Admin.view.sales.Controller', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.sale',
    onShowSale: function(){
        const me = this,
        refs = me.getReferences(),
        saleFromDate = refs.saleStartDate,
        saleToDate = refs.saleEndDate,
        saleMachine = refs.saleMachine,
        saleError = refs.saleError,
        saleProduct = refs.saleProduct,
        view = me.getView(),
        store = view.getStore();
        const newStore = Ext.create({
            xtype: 'adminstore',
            autoLoad: true,
            page: 1,
            url: Routes.sales_path({
                datefrom: saleFromDate.getValue(),
                dateto: saleToDate.getValue(),
                machine: saleMachine.getValue(),
                sale_dispense_error:  saleError.getValue(),
                product: saleProduct.getValue(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            })
        });
        view.bindStore(newStore);
        view.down('pagingtoolbar').setStore(newStore)
    },
    onExportAsCSV: function(){
        const me=this;
        const view = me.getView();
        const store = view.getStore();
        window.open(view.down('pagingtoolbar').getStore().url.replace('.json', '.csv'), "_blank")
    }
});