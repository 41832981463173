Ext.define('Admin.view.Entrance',{
    extend: 'Ext.grid.Panel',
    xtype: 'admindashboard',
    title: 'Entrance Management',
    store:[],
    columns:[
        {
            dataIndex: 'store',
            text: 'Try'
        }
    ]
});