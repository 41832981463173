Ext.define('Admin.view.machine.config.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'machineconfigform',
    width: 350,
    formTitleAttribute: 'tag',
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    items:[
        {
            name: 'configs[tag]',
            fieldLabel: 'Tag'
        },
        {
            name: 'configs[value]',
            fieldLabel: 'Value'
        }
    ]
})