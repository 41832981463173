Ext.define('Admin.view.machines.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'adminmachines',
    xtype: 'adminmachine',
    title: 'Machine Management',
    url: 'machines_path',
    form: 'adminmachineform',
    customTBarItems: [
        {
            text: 'Push Master Data',
            handler: function(){
                const me = this;
                const machines = me.up().up().getSelection().map(record => {
                    return record.get('id')
                })
                if(machines.length == 0){
                    notify("Please select machine(s) to push data.", false, {position: 'center'})
                    return
                }
                Ext.Ajax.request({
                    url: Routes.push_data_machines_path({machines}),
                    callback: function(one, two, three){
                        if(two){
                            const response = JSON.parse(three.responseText)
                            notify(response.message, response.success, {position: 'center'})
                        }
                    }
                })
            }
        }
    ],
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 200
        },
        {
            dataIndex: 'current_template',
            text: 'Current Template',
            width: 150
        },
        {
            dataIndex: 'current_price',
            text: 'Current Price',
            width: 150
        },
        {
            dataIndex: 'location_name',
            text: 'Location'
        },
        {
            dataIndex: 'driver_name',
            text: 'Driver'
        },
        {
            dataIndex: 'ip_add',
            text: 'IP Address',
            width: 100
        }
    ]
})