Ext.define('Admin.view.machine.code.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'machinecodeform',
    width: 350,
    formTitleAttribute: 'code',
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    initComponent: function(){
        const me = this;
        const current_machine = me.record.get('machine_id') || false;
        console.log(me.record, current_machine)
        Ext.apply(me,{
            items:[
                {
                    readOnly: true,
                    name: 'code[code]',
                    fieldLabel: 'Unique Code'
                },
                {
                    name: 'code[machine_id]',
                    fieldLabel: 'Machine',
                    allowBlank: true,
                    xtype: 'combobox',
                    displayField: 'name',
                    valueField: 'id',
                    store: {type: 'adminstore', url: Routes.code_machines_path({machine_id: current_machine})},
                    queryMode: 'local',
                    triggerAction: 'all'
                }
            ]
        })
        me.callParent(arguments)
    }
})