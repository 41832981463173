Ext.define('Admin.view.price.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminpriceform',
    width: 350,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    items:[
        {
            name: 'price[name]',
            fieldLabel: 'Name'
        },
        {
            xtype: 'checkboxfield',
            fieldLabel: 'Is Active?',
            name: 'price[is_active]',
            uncheckedValue: false,
            value: true
        }
    ]
})