Ext.define('Admin.view.menu.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminmenuform',
    width: 350,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    items:[
        {
            xtype: 'combobox',
            name: 'menu[menu_id]',
            fieldLabel: 'Parent',
            allowBlank: true,
            displayField: 'name',
            valueField: 'id',
            store: {
                type: 'adminstore',
                url: Routes.option_menus_path()
            },
            queryMode: 'local'
        },
        {
            name: 'menu[name]',
            fieldLabel: 'Name'
        },
        {
            name: 'menu[path]',
            fieldLabel: 'Path',
            allowBlank: true,
            value: '#'
        },
        {
            name: 'menu[kontroler]',
            fieldLabel: 'Controller',
            allowBlank: true
        },
        {
            name: 'menu[icon]',
            fieldLabel: 'Icon',
            allowBlank: true
        },
        {
            name: 'menu[ordinal]',
            fieldLabel: 'Sequence'
        },
        {
            xtype: 'checkboxfield',
            fieldLabel: 'Is Active?',
            name: 'menu[is_active]',
            uncheckedValue: false,
            value: true
        },
        {
            xtype: 'checkboxfield',
            fieldLabel: 'Is Link?',
            name: 'menu[is_accessible_by_user]',
            uncheckedValue: false,
            value: true
        }
    ]
})