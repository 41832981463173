Ext.define('Admin.view.form.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminproductform',
    width: '50%',
    scrollable: true,
    initComponent: function(){
        const me = this;
        const record = me.record;
        Ext.apply(me, {
            xtype: 'container',
            layout: 'hbox',
            bodyPadding: 0,
            items:[
                {
                    width: '74.9%',
                    title: 'General',
                    xtype: 'panel',
                    bodyPadding: 5,
                    defaults: {
                        width: '100%',
                        xtype: 'textfield',
                        anchor:'100%',
                        allowBlank: false
                    },
                    items: [
                        {
                            name: 'product[name]',
                            fieldLabel: 'Name'
                        },
                        {
                            xtype: 'textarea',
                            name: 'product[description]',
                            fieldLabel: 'Description',
                            allowBlank: true,
                        },
                        {
                            name: 'product[weight]',
                            fieldLabel: 'Weight (grams)',
                            allowBlank: true,
                            value: 0
                        },
                        {
                            xtype: 'fileuploadfield',
                            fieldLabel: 'Image',
                            name: 'product[avatar]',
                            allowBlank: true
                        },
                        {
                            xtype: 'checkboxfield',
                            fieldLabel: 'Is Active?',
                            name: 'product[is_active]',
                            uncheckedValue: false,
                            value: true
                        }
                    ]
                },
                {
                    width: '25%',
                    height: '100%',
                    scrollable: true,
                    itemId: 'price-class-grid',
                    disableConfig: { disableAll: true},
                    title: 'Prices',
                    xtype: 'adminproductprice',
                    preventHeader: false,
                    url: {
                        path: 'price_product_path',
                        id: record ? record.get('id') : 0
                    },
                    selModel: 'cellmodel',
                    plugins: {
                       ptype: 'cellediting',
                       clicksToEdit: 1
                    }
                }
            ]
            
        });
        me.callParent(arguments)
    },
    beforeSubmit: function(){
        this.setParams({...this.getPriceClass()})
    },
    getPriceClass: function(){
        const self=this;
      const store = self.down('#price-class-grid').getStore().getData();
      const count = store.count();
      let a = 0;
      const params = {};
      while(count > a){
         const record = store.getAt(a).getData();
         if(typeof record.id === 'number')  params[`product[prices_attributes][${a}][id]`] = record.id
         params[`product[prices_attributes][${a}][amount]`] = record.amount;
         params[`product[prices_attributes][${a}][price_id]`] = record.price_id;
         a++;
      }
      return params;
    }
    
})