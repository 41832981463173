Ext.define('Admin.view.sale.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminsaleform',
    width: 350,
    formTitleAttribute: 'order_id',
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: true
    },
    items:[
        {
            xtype: 'textarea',
            fieldLabel: 'Remark',
            labelAlign: 'top',
            name: 'sale[remarks]',
            height: 250
        }
    ]
})