Ext.define('Admin.view.product.Price',{
    extend: 'Admin.view.template.Grid',
    xtype: 'adminproductprice',
    title: 'Price Management',
    columns: [
        {
            dataIndex: 'name',
            text: 'Name', 
            menuDisabled: true,
            sortable: false
        },
        {
            dataIndex: 'amount',
            text: 'Price',
            editor: 'textfield', 
            menuDisabled: true,
            sortable: false
        }
    ]
})