Ext.define('Admin.view.roles.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'adminroles',
    xtype: 'adminrole',
    title: 'Role Management',
    url: 'roles_path',
    form: 'adminroleform',
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 200
        },
        {
            dataIndex: 'description',
            text: 'Description',
            width: 300
        }
    ]
})