Ext.define('Admin.view.user.Grid',{
    extend: 'Admin.view.template.Grid',
    xtype: 'adminuser',
    title: 'User Management',
    url: 'users_users_path',
    columns: [
        {
            dataIndex: 'email',
            text: 'Email',
            width: 300
        },
        {
            dataIndex: 'name',
            text: 'Name',
            width: 300
        },
        {
            dataIndex: 'pin',
            text: 'PIN',
            width: 70,
            align: 'center'
        }
    ]
})