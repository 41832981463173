Ext.define('Admin.view.zones.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'adminzones',
    xtype: 'adminzone',
    title: 'Zone Management',
    url: 'zones_path',
    form: 'adminzoneform',
    disableConfig:{
        disableIsActiveColumn: true
    },
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 200
        },
        {
            dataIndex: 'description',
            text: 'Description'
        }
    ]
})