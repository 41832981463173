Ext.define('Admin.view.admin.template.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'admintemplateform',
    width: '90%',
    height: '100%',
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    scrollable: true,
    initComponent: function(){
        const me = this;
        const layoutstore = Ext.create({
            xtype: 'adminstore',
            url: Routes.option_layouts_path(),
            pageSize: 0
        })
        Ext.apply(me,{
            items:[
                {
                    name: 'template[name]',
                    fieldLabel: 'Name',
                    width: '50%',
                    anchor: '50%'
                },
                {
                    xtype: 'combobox',
                    name: 'template[layout_id]',
                    fieldLabel: 'Layout',
                    displayField: 'name',
                    valueField: 'id',
                    query: 'local',
                    store: layoutstore,
                    width: '50%',
                    anchor: '50%',
                    listeners: {
                        change: function(cmb, newVal, oldVal){
                            const panel = cmb.up().down('#layout-details-container');
                            panel.removeAll(true)
                            // panel.update();
                            Ext.Ajax.request({
                                url: Routes.details_templates_path(),
                                method: 'get',
                                params: {
                                    layout_id: newVal,
                                    template_id: me.record ? me.record.get('id') : ""
                                },
                                callback: function(opts, success, response){
                                    const responseJSON = Ext.JSON.decode(response.responseText);
                                    if(!responseJSON){
                                        notify('Somethine went wrong.\Please call web administrator', false)
                                        return
                                    }
                                    const items = me.layoutLabels();
                                    let item_count = 0;
                                    responseJSON.data.map( (layout, index) => {
                                        var item = { 
                                            xtype: 'container',
                                            items: {
                                                html: `<span class="template-slot-label">${item_count}</span>`, 
                                                width: '100%' 
                                            }
                                        }
                                        if(layout.is_active){
                                            store = Ext.create({
                                                xtype: 'adminstore',
                                                url: Routes.option_products_path()
                                            })
                                            item = {
                                                xtype: 'container',
                                                layout: 'vbox',
                                                defaults:{
                                                    allowBlank: false,
                                                },
                                                items: [
                                                    {
                                                        html: `<span class="template-slot-label">${item_count}</span>`,
                                                        width: '100%',
                                                        style: "border: none;"
                                                    },
                                                    {
                                                        xtype: 'combobox',
                                                        store: store,
                                                        emptyText: 'Product',
                                                        value: layout.product_id,
                                                        name: `template[template_details_attributes][${index}][product_id]`,
                                                        valueField: 'id',
                                                        displayField: 'name'
                                                    },
                                                    {
                                                        xtype: 'textfield',
                                                        emptyText: 'Max Qty',
                                                        value: layout.max_qty,
                                                        name: `template[template_details_attributes][${index}][max_qty]`
                                                    },
                                                    {
                                                        xtype: 'hiddenfield',
                                                        value: layout.id,
                                                        name: `template[template_details_attributes][${index}][id]`
                                                    },
                                                    {
                                                        xtype: 'hiddenfield',
                                                        value: layout.layout_detail_id,
                                                        name: `template[template_details_attributes][${index}][layout_detail_id]`
                                                    }
                                                ]
                                            }
                                        }
                                        items.push(item);
                                        item_count++;
                                    })
                                    const panelRemove = cmb.up().down('#layout-details-container');
                                    panelRemove.add(items)
                                }
                            })
                        }
                    }
                },
                {
                    xtype: 'panel',
                    title: 'Template Details',
                    itemId: 'layout-details-container',
                    autoDestroy: true,
                    // cls: "set-table-height",
                    layout: {
                        type: 'table',
                        columns: 10,
                        tableAttrs: {
                            style: {
                                width: '99%',
                                borderCollapse: 1
                            }
                        },
                        tdAttrs: {
                            style: {
                                width: `${100/10}%`,
                                textAlign: 'center',
                                border: '1px solid #000'
                            }
                        }
                    },
                    style: {
                        width: '100%',
                        height: 'calc(100% - 60px)'
                    },
                    scrollable: true,
                    defaults: {
                        width: '100%'
                    },
                    listeners: {
                        afterLayout: function(){
                            me.center()
                        }
                    }
                }
            ]
        });
        me.callParent(arguments)
    },
    layoutLabels: function(){
        return [
            {
                html: '#1'
            },
            {
                html: '#2'
            },
            {
                html: '#3'
            },
            {
                html: '#4'
            },
            {
                html: '#5'
            },
            {
                html: '#6'
            },
            {
                html: '#7'
            },
            {
                html: '#8'
            },
            {
                html: '#9'
            },
            {
                html: '#10'
            }
        ]
    }
})