Ext.define('Admin.view.user.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminuserform',
    width: 600,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    submitEmptyText: false,
    initComponent: function(){
        const me=this;
        console.log(me.record)
        const roleStore = Ext.create({
            xtype: 'adminstore',
            url: Routes.option_roles_path(),
            pageSize:0
        });
        Ext.apply(me,{
            items:[
                {
                    fieldLabel: 'Name',
                    name: 'user[name]'
                },
                {
                    fieldLabel: 'Email',
                    name: 'user[email]',
                    listeners:{
                        change: function(email, value){
                            const username = value.substring(0, value.lastIndexOf("@"))
                            email.up().down('#user-username').setValue(username)
                        }
                    }
                },
                {
                    fieldLabel: 'Username',
                    itemId: 'user-username',
                    name: 'user[username]'
                },
                {
                    fieldLabel: 'Role',
                    name: 'user[role_id]',
                    xtype: 'combobox',
                    displayField: 'name',
                    valueField: 'id',
                    query: 'local',
                    store: roleStore
                },
                {
                    fieldLabel: 'PIN',
                    itemId: 'user-pin',
                    name: 'user[pin]',
                    editable: false,
                    triggers: {
                        reload: {
                            weight: 1,
                            cls: Ext.baseCSSPrefix + 'x-fa fa-sync',
                            scope: 'this',
                            handler: 'onGeneratePin'
                        }
                    },
                    onGeneratePin: function(){
                        const self = this;
                        const generated_pin = me.pinGenerator();
                        self.setValue(generated_pin);
                
                    }
                },
                {
                    fieldLabel: 'Password',
                    name: 'user[password]',
                    allowBlank: Boolean(me?.record)
                },
                {
                    xtype: 'checkboxfield',
                    fieldLabel: 'Is Active?',
                    name: 'user[is_active]',
                    uncheckedValue: false,
                    value: true
                }
            ],
        })
        me.callParent(arguments)
    },
    pinGenerator: function(){
        return Math.floor(100000 + Math.random() * 900000);
    }
})