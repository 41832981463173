Ext.define('Admin.view.menus.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'adminmenus',
    xtype: 'adminmenu',
    title: 'Menu Management',
    url: 'menus_path',
    form: 'adminmenuform',
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 300
        },
        {
            dataIndex: 'parent_name',
            text: 'Parent Menu',
            width: 200,
            sortable: false
        },
        {
            dataIndex: 'ordinal',
            text: 'Sequence'
        }
    ]
})