Ext.define('Admin.view.folder.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminlayoutform',
    width: 600,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    initComponent: function(){
        const me = this;
        const store = Ext.create({
            xtype: 'adminstore',
            url: Routes.details_layouts_path({layout_id: me.record ? me.record.get('id') : "" }),
            pageSize: 0
        })
        Ext.apply(me,{
            items:[
                {
                    name: 'layout[name]',
                    fieldLabel: 'Name'
                },
                {
                    xtype: 'grid',
                    itemId: 'layout-detail-grid',
                    store,
                    width: '100%',
                    enableLocking: true,
                    selModel: 'cellmodel',
                    plugins: {
                        ptype: 'cellediting',
                        clicksToEdit: 2,
                    },
                    columns: {
                        defaults: {
                            width: 50,
                            xtype: 'checkcolumn',
                            align: 'center',
                            disableMenu: true,
                            resizable: false,
                            renderer: function(value, meta, record){
                                console.log(value);
                                return `<i class="x-fa fa-${value ? 'check x-color-green' : 'times x-color-red'}" ></i>`
                            } 
                        },
                        items: [
                            {
                                text: 'Level',
                                dataIndex: 'tied',
                                width: 60,
                                locked: true,
                                xtype: null,
                                editor: 'null',
                                renderer: null
                            },
                            {
                                text: '#1',
                                dataIndex: '1'
                            },
                            {
                                text: '#2',
                                dataIndex: '2'
                            },
                            {
                                text: '#3',
                                dataIndex: '3'
                            },
                            {
                                text: '#4',
                                dataIndex: '4'
                            },
                            {
                                text: '#5',
                                dataIndex: '5'
                            },
                            {
                                text: '#6',
                                dataIndex: '6',
                                width: 50
                            },
                            {
                                text: '#7',
                                dataIndex: '7'
                            },
                            {
                                text: '#8',
                                dataIndex: '8'
                            },
                            {
                                text: '#9',
                                dataIndex: '9'
                            },
                            {
                                text: '#10',
                                dataIndex: '10'
                            }
                        ]
                    }
                }
            ]
        });
        me.callParent(arguments)
    },
    beforeSubmit: function(){
        const me = this;
        const store = me.down('grid').getStore();
        const records = store.getData().items;
        const dataKey = [1,2,3,4,5,6,7,8,9,10];
        const params = {};
        var paramsCounter = 0;
        records.map(data => {
            dataKey.map(key => {
                const id = data.get(`slot_${key}_id`);
                const slot = data.get(`slot_${key}_no`);
                const is_active = data.get(key);
                // [key];
                params[`layout[layout_details_attributes][${paramsCounter}][id]`] = id;
                params[`layout[layout_details_attributes][${paramsCounter}][tied]`] = data.get('tied');
                params[`layout[layout_details_attributes][${paramsCounter}][slot]`] = slot;
                params[`layout[layout_details_attributes][${paramsCounter}][is_active]`] = is_active;
                paramsCounter++;
            })
        })
        console.log(params)
        me.setParams(params);
    }
})