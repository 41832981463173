Ext.define('Admin.view.stocks.Grid',{
    extend: 'Admin.view.template.Grid',
    xtype: 'machinestocks',
    title: 'Machine Stock Management',
    url: 'machine_stocks_path',
    disableConfig: {
        disableDefaultConfig: true,
        disableStatusColumn: true,
        disableIsActiveColumn: true,
        disablePagination: false,
        disable: {
            search: true,
            update: true,
            create: true,
            destroy: true
        }
    },
    controller: 'stock',
    storeConfig:{
        pageSize: 60
    },
    scrollable: true,
    tbar: {
        xtype: 'container',
        width: '100%',
        layout: {
            type: 'vbox'
        },
        defaults:{
            xtype: 'toolbar',
            width: '100%'
        },
        items: [
            {
                items: [
                    {
                        xtype: 'combobox',
                        reference: 'stockViewBy',
                        fieldLabel: 'View By',
                        store: [['product', 'Product'],['slot', 'Slot'], ['machine', 'Machine']],
                        forceSelection: false,
                        position: 2,
                        labelWidth: 60,
                        value: 'slot',
                        queryMode: 'local',
                        width: 200,
                        editable: false
                    }
                ]
            },
            {
                items: [
                    {
                        xtype: 'tagfield',
                        reference: 'stockMachineOption',
                        fieldLabel: 'Machine',
                        displayField: 'name',
                        valueField: 'id',
                        store: {
                            type: 'adminstore',
                            url: Routes.option_machines_path()
                        },
                        forceSelection: false,
                        position: 2,
                        labelWidth: 60,
                        queryMode: 'local',
                        maxWidth: 500,
                        listeners: {
                            change: 'onMachineChange'
                        }
                    
                    },
                    {
                        xtype: 'button',
                        text: 'Show',
                        handler: 'onShowSale'
                    },
                    {
                        xtype: 'button',
                        text: 'Show All',
                        handler: 'onShowAllStock'
                    },
                    '->',
                    {
                        xtype: 'button',
                        text: 'Export',
                        ui: 'default',
                        handler: 'onExportCSV'
                    }
                ]
            }
        ]
    },
    columns: window.STOCK_HIDDEN_COLUMNS.slot
})