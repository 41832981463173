Ext.define('Admin.view.machine.config.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'machineconfigs',
    xtype: 'machineconfig',
    title: 'Machine Configs Management',
    url: 'machine_configs_path',
    form: 'machineconfigform',
    disableConfig: {
        disableIsActiveColumn: true
    },
    columns: [
        {
            dataIndex: 'tag',
            text: 'Tag',
            width: 200
        },
        {
            dataIndex: 'value',
            text: 'Value',
            width: 500
        }
    ],
    customTBarItems: [{
        position: 0,
        uiCls: ['cancel'],
        text: 'Push Configs to All Machine',
        handler: function(){
            Ext.Ajax.request({
                url: Routes.push_machine_configs_path(),
                method: 'get',
                callback: function(opts, success, response){
                    const responseJson = Ext.JSON.decode(response.responseText)
                    notify(responseJson.message, success, { position: 'center' });
                }
            })
        }
    }]
})