Ext.define('Admin.view.machine.Price', {
    extend: 'Admin.view.template.Grid',
    xtype: 'adminmachineprice',
    initComponent: function(){
        const me = this;
        const comboEditor = Ext.create({
            xtype: 'combobox',
            displayField: 'name',
            valueField: 'id',
            store: {type: 'adminstore', url: Routes.option_prices_path(), autoLoad: true},
            forceSelection: true
        })
        const comboRenderer = function(cmb){
            return function(value){
                const store = cmb.getStore();
                var idx = store.find(cmb.valueField, value);
                var rec = store.getAt(idx);
                return (rec === null ? '' : rec.get(cmb.displayField) );
            }
        }
        Ext.apply(me,{
            customTBarItems: [{
                position: 0,
                text: 'New Price Schedule',
                handler: function(){
                    const store = this.up().up().up().down('#machine-price-grid').getStore()
                    store.add({price_id: null});
                }
            }],
            columns: [
                {
                    dataIndex: 'price_id',
                    text: 'Price Class',
                    editor: comboEditor,
                    renderer: comboRenderer(comboEditor),
                    sortable: false,
                    width: '30%'
                },
                {
                    text: 'Valid From',
                    dataIndex: 'valid_from',
                    editor: { 
                        xtype: 'datetimefield', 
                        format: 'd/m/Y H:i' 
                    }, 
                    xtype: 'datecolumn', 
                    format:'d/m/Y H:i',
                    menuDisabled: true,
                    sortable: false,
                    width: '30%',
                    submitFormat: 'C'
                },
                {
                    text: 'Valid Until',
                    dataIndex: 'valid_to',
                    width: '30%',
                    editor: { 
                        xtype: 'datetimefield', 
                        format: 'd/m/Y H:i' 
                    }, 
                    xtype: 'datecolumn', 
                    format:'d/m/Y H:i',
                    menuDisabled: true,
                    sortable: false,
                    width: '30%',
                    submitFormat: 'C'
                },
                {
                    xtype: 'actioncolumn',
                    width: 30,
                    align: 'center',
                    items: [
                        {
                            iconCls: 'x-fa fa-trash x-btn-col-icon-danger',
                            tooltip: 'Remove',
                            text: 'Remove',
                            handler: function(grid, rowIndex, colIndex, item, e, record, row){
                                if( typeof record.get('id') == 'number' ){
                                    Ext.Ajax.request({
                                        method: 'delete',
                                        params:{
                                            authenticity_token: authenticityToken()
                                        },
                                        url: Routes.delete_price_machine_path(record.get('id')),
                                        callback: function(opts, success, err){
                                            if(success){
                                                grid.getStore().remove(record)
                                            }
                                        }
                                    })
                                }else{
                                    grid.getStore().remove(record)
                                }
                            }
                        }
                    ]
                }
            ]
        })
        me.callParent(arguments)
    }
})