Ext.define('Admin.view.zone.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminzoneform',
    width: 350,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    items:[
        {
            name: 'zone[name]',
            fieldLabel: 'Name'
        },
        {
            name: 'zone[description]',
            fieldLabel: 'Address',
            xtype: 'textarea'
        }
    ]
})