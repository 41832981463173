Ext.define('Admin.view.stock.Controller', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.stock',
    onShowSale: function(){
        const me = this,
            refs = me.getReferences(),
            stockMachine = refs.stockMachineOption.getValue(),
            viewBy = refs.stockViewBy.getValue(),
            view = me.getView();
        if(!stockMachine.length){
            notify('Please select machine.', false, { position: 'center' })
            return;
        }
        this.showStock(view, stockMachine, viewBy)
    },
    onShowAllStock: function(){
        const me = this,
            refs = me.getReferences(),
            viewBy = refs.stockViewBy.getValue(),
            view = me.getView();
        refs.stockMachineOption.clearValue();
        this.showStock(view, 'all', viewBy)
    },
    showStock: function(view, stockMachine, viewBy){
        const newStore = Ext.create({
            xtype: 'adminstore',
            autoLoad: true,
            page: 1,
            pageSize: 60,
            url: Routes.machine_stocks_path({
                machine: stockMachine,
                viewby: viewBy
            })
        });
        const columns = view.getColumns();
        const viewByHeader = columns.find(x => x.dataIndex.includes("current_")).dataIndex.replace(/current_|_qty/g,"");
        if(viewByHeader != viewBy ){
            view.reconfigure(newStore, window.STOCK_HIDDEN_COLUMNS[viewBy])
        }else{
            view.bindStore(newStore);
        }
        view.down('pagingtoolbar').setStore(newStore);
    },
    onMachineChange: function(tag, newVal, oldVal){
        const store = tag.getStore()
        if(newVal.length > 1){
            if(newVal[newVal.length-1] == "all"){
                tag.setValue(store.getRange())
            }else{

            }
        }
    },
    onExportCSV: function(){
        const me = this,
            refs = me.getReferences(),
            stockMachine = refs.stockMachineOption.getValue(),
            viewBy = refs.stockViewBy.getValue(),
            view = me.getView();
            
        window.open(Routes.machine_stocks_path({format: 'csv', 
            machine: stockMachine,
            viewby: viewBy
        }));
    },
    beforerender: function(view){
        view.reconfigure([], STOCK_HIDDEN_COLUMNS.slot)
    },
    initGridComponent: function(){
        const self = this;
        Ext.apply(self,{
            columns: STOCK_HIDDEN_COLUMNS.slot
        })
        self.callParent(arguments)
    }
});