import Glide from '@glidejs/glide';
Ext.define('Admin.view.banners.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'adminbanners',
    xtype: 'adminbanner',
    title: 'banner Management',
    url: 'banners_path',
    disableConfig: {
        disableDefaultConfig: true,
        disableStatusColumn: true,
        disableIsActiveColumn: true
    },
    customActionColumns:[
        {
            position: 0,
            iconCls: 'fa fa-eye',
            handler: function(grid, rowIndex, colIndex, item, e, record, row){
                const urls = record.get('image_urls');
                if(urls.length === 0){
                    notify('No image(s) found.', false, {position: 'center'});
                    return;
                }
                let images = "";
                urls.map(url => {
                    images += `<img class="glide__slide" src="${url}" width="100%" height="100%" />`
                })
                Ext.create('Ext.panel.Panel',{
                    title: 'Images',
                    width: '80%',
                    height: '100%',
                    modal: true,
                    floating: true,
                    draggable: false,
                    closeAction: 'destroy',
                    closable: true,
                    html: '<div class="glide">'+
                        '<div data-glide-el="track" class="glide__track">'+
                            '<ul class="glide__slides">'+
                                images+
                            '</ul>'+
                        '</div>'+
                        '<div data-glide-el="controls">'+
                            '<button class="slider__arrow slider__arrow--prev glide__arrow glide__arrow--prev" data-glide-dir="<">'+
                                '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">'+
                                    '<path d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z"></path>'+
                                '</svg>'+
                            '</button>'+
                            '<button class="slider__arrow slider__arrow--next glide__arrow glide__arrow--next" data-glide-dir=">">'+
                                '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">'+
                                    '<path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path>'+
                                '</svg>'+
                            '</button>'+
                        '</div>'+
                    '</div>',
                    listeners: {
                        render: function(){
                            setTimeout(() => {
                                const glides =  new Glide('.glide', {
                                    type: 'slider',
                                    gap: 0
                                });
                                glides.mount()
                            }, 200)
                        }
                    }
                }).show();
            }
        }
    ],
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 300
        },
        {
            dataIndex: 'machines',
            text: 'Machines',
            width: 200,
            sortable: false,
            cellWrap: true
        },
        {
            dataIndex: 'valid_from',
            text: 'Valid From',
            xtype: 'datecolumn', 
            format:'d/m/Y H:i',
            width: 150
        },
        {
            dataIndex: 'valid_to',
            text: 'Valid Until',
            xtype: 'datecolumn',
            format: 'd/m/Y H:i',
            width: 150
        }
    ]
})