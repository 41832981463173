Ext.define('Admin.view.admin.template.Grid',{
    extend: 'Admin.view.template.Grid',
    xtype: 'admintemplate',
    title: 'Template Management',
    url: 'templates_path',
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 300
        },
        {
            dataIndex: 'layout_name',
            text: 'Layout',
            width: 300
        }
    ],
    disableConfig:{
        disableIsActiveColumn: true
    },
    customActionColumns: [
        {
            position: 0,
            iconCls: 'x-fa fa-clone x-btn-col-icon-info',
            tooltip: 'Copy Template',
            handler: function(grid, rowIndex, colIndex, item, e, record, row){
                Ext.Msg.confirm('Copy Template',`Are you sure want to copy "${record.get('name')}" ?`, function(ans){
                    if(ans == "yes"){
                        grid.mask('Copying...');
                        Ext.Ajax.request({
                            method: 'get',
                            url: Routes.duplicate_template_path(record.get('id')),
                            callback: function(opts, success, status){
                                const responseJSON = Ext.JSON.decode(status.responseText);
                                notify(responseJSON.message);
                                grid.getStore().reload();
                                grid.unmask()
                            }
                        })
                    }
                })
            }
        }
    ]
})