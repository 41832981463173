Ext.define('Admin.view.folder.Grid',{
    extend: 'Admin.view.template.Grid',
    xtype: 'folderlayout',
    title: 'Backup Management',
    url: 'folders_path',
    columns: [
        {
            dataIndex: 'name',
            text: 'Name',
            width: 400
        },
        {
            dataIndex: 'created_at',
            text: 'Generated At',
            width: 350,
            xtype: 'datecolumn',
            format: 'd/m/Y h:i A',
        }
    ],
    disableConfig:{
        disableIsActiveColumn: true,
        disable: {
            search: false,
            create: true,
            update: true,
            destroy: true,
            read: true
        }
    },
    customActionColumns: [
        {
            position: 0,
            iconCls: 'x-fa fas fa-save x-btn-col-icon-success',
            tooltip: 'Download Report',
            handler: function(grid, rowIndex, colIndex, item, e, record, row){
                console.log(record)
                const file_path = record.get('file_path');
                if(!file_path){
                    notify('CSV File not found.', false);
                    return;
                }
                window.open(file_path);

                // Ext.Msg.confirm('Copy Layout',`Are you sure want to copy "${record.get('name')}" ?`, function(ans){
                //     if(ans == "yes"){
                //         grid.mask('Copying...');
                //         Ext.Ajax.request({
                //             method: 'get',
                //             url: Routes.duplicate_layout_path(record.get('id')),
                //             callback: function(opts, success, status){
                //                 const responseJSON = Ext.JSON.decode(status.responseText);
                //                 notify(responseJSON.message);
                //                 grid.getStore().reload();
                //                 grid.unmask()
                //             }
                //         })
                //     }
                // })
            }
        }
    ]
})
