import consumer from "./consumer";
const TIMEOUT = {};
consumer.subscriptions.create("HealthChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('Kabli Connected')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('disconnected')
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log(data)
    const machine_timeout = TIMEOUT[data.machine_id];
    clearTimeout(machine_timeout);
    TIMEOUT[data.machine_id] = false;
    const grid = Ext.ComponentQuery.query("grid[itemId=machineStatusGrid]")[0];
    if(!grid)
      return
    const store = grid.getStore();
    const record = store.findRecord('id', data.machine_id);
    if(record){
      const timestamp = new Date(data.generated_at);
      const today = new Date()
      const diff = ((today.getTime() - timestamp.getTime()) / 1000) / 60;
      if(diff > 15)
        return
      const healthcheck = {
        "health.vending_status": data.vending_status,
        "health.vending_temperature": data.vending_temperature,
        "health.pi_cpu_usage": data.pi_cpu_usage,
        "health.pi_cpu_temperature": data.pi_cpu_temperature,
        "health.pi_storage": data.pi_storage,
        "health.ping_speed": data.ping_speed,
        "health.generated_at": data.generated_at
      }
      const healthcheck_record = {}
      Object.keys(healthcheck).map(key => { healthcheck_record[key.replace('health.','')] = healthcheck[key] })
      healthcheck["health"] = healthcheck_record;
      record.set(healthcheck);
      record.commit();

      grid.bindStore(store);

      TIMEOUT[data.machine_id] = setTimeout(()=>{
        const healthcheck_default = {
          "health.vending_status": 0,
          "health.vending_temperature": 0,
          "health.pi_cpu_usage": 0,
          "health.pi_cpu_temperature": 0,
          "health.pi_storage": 0,
          "health.ping_speed": 0,
          "health.generated_at": record.get('health.generated_at')
        }
        const healthcheck_record = {}
        Object.keys(healthcheck_default).map(key => { healthcheck_record[key.replace('health.','')] = healthcheck_default[key] })
        healthcheck_default["health"] = healthcheck_record;
        record.set(healthcheck_default);
        record.commit();
        grid.bindStore(store);
      }, 960000)
    }
  }
});
