Ext.define('Admin.view.role.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminroleform',
    width: 500,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    initComponent: function(){
        const me = this;
        const menuStore = Ext.create({
            xtype: 'adminstore',
            url: Routes.option_menus_path(),
            pageSize:0
        });
        const machineStore = Ext.create({
            xtype: 'adminstore',
            url: Routes.option_machines_path(),
            pageSize:0
        });
        // const machine_values = me?.record?.get('role_machines')?.map(x => x?.machine_id) || [];
        // const menu_values = me?.record?.get('role_menus')?.map(x => x?.menu_id) || [];
        // console.log(menu_values, )
        Ext.apply(me, {
            items:[
                {
                    name: 'role[name]',
                    fieldLabel: 'Name'
                },
                {
                    name: 'role[description]',
                    fieldLabel: 'Desciprion',
                    xtype: 'textarea'
                },
                {
                    xtype: 'checkboxfield',
                    fieldLabel: 'Is Active?',
                    name: 'role[is_active]',
                    uncheckedValue: false,
                    value: true
                },
                {
                    fieldLabel: 'Access Menus',
                    name:'role[role_menus_attributes][][menu_id]',
                    xtype: 'tagfield',
                    displayField: 'name',
                    valueField: 'id',
                    queryMode: 'local',
                    store: menuStore,
                    allowBlank: true,
                    recordKey: 'menus'
                },
                {
                    fieldLabel: 'Access Machines',
                    name:'role[role_machines_attributes][][machine_id]',
                    xtype: 'tagfield',
                    displayField: 'name',
                    valueField: 'id',
                    queryMode: 'local',
                    store: machineStore,
                    allowBlank: true,
                    recordKey: 'machines'
                }
            ]
        });

        me.callParent(arguments)
    },
    beforeSubmit: function(){
        const me = this;
        me.setParams
    }
})