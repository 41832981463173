const color_coding = {
    "0": "fa-circle text-danger",
    "1": "fa-circle text-warning",
    "2": "fa-circle text-success",
    "4": "fa-times text-danger",
    default: 'fa-circle text-'
}

function check_data_online(record){
    val = new Date(record.get('health.generated_at'));
    const today = new Date();
    const diff = ((today.getTime() - val.getTime()) / 1000) / 60;
    return diff < 15;
}

function pi_percentage(unit="%"){
    return function(value, meta, record){
        if(!check_data_online(record)) return '';
        const val = parseFloat(value);
        const percentage = Math.round(val * 100);
        if(percentage>0)
            return `${percentage} ${unit}`
        else
            return "";
    }
}

function render_online(value, meta, record){
    if(typeof value == "boolean"){
        return `<i class="x-fa fa-circle x-color-${value ? "green" : "red"}"></a>`;
    }
    const is_online = check_data_online(record)
    if(is_online){
        if(new Date(value).getTime() == val.getTime())
            return `<i class="x-fa fa-circle x-color-${is_online ? "green" : "red"}"></a>`;
        return `<i class="x-fa ${color_coding[value] || (value ? "fa-circle x-color-green" : "fa-circle x-color-red")}"></a>`;
    }
    return '<i class="x-fa fa-circle x-color-red"><i>';    
}
Ext.define('Admin.view.machine.status.Grid',{
    extend: 'Ext.grid.Panel',
    xtype: 'machinestatus',
    title: 'Machine Status Management',
    itemId: 'machineStatusGrid',
    store: {
        type: 'adminstore',
        url: Routes.machine_statuses_path({timezone: Intl.DateTimeFormat().resolvedOptions().timeZone})
    },
    columns: {
        defaults: {
            menuDisabled: true,
            defaults: {
                menuDisabled: true,
                sortable: false,
                align: 'center'
            }
        },
        items: [
            {
                dataIndex: 'name',
                text: 'Machine',
                locked: true,
                width: 150
            },
            {
                dataIndex: 'location_name',
                text: 'Location',
                sortable: false,
                locked: true
            },
            {
                dataIndex: 'ip_add',
                text: 'IP Address',
                width: 100,
                locked: true
            },
            {
                text: 'Summary',
                columns: [
                    {
                        dataIndex: 'health.generated_at',
                        text: 'Last Online',
                        xtype: 'datecolumn',
                        format: 'd/m/Y h:i A',
                        width: 150
                    },
                    {
                        text: 'Online',
                        dataIndex: 'health.generated_at',
                        renderer: render_online
                    },
                    {
                        text: 'Ping',
                        dataIndex: 'health.ping_speed',
                        renderer: function(value, meta, record){
                            if(!check_data_online(record)) return '';
                            return value ? `${value}ms` : '<i class="x-fa fa-circle x-color-red"><i></i>';
                        }
                    }
                ]
            },
            {
                text: 'Vending Summary',
                columns: [
                    {
                        text: 'Status',
                        dataIndex: 'health.vending_status',
                        width: 80,
                        renderer: render_online
                    },
                    {
                        text: 'Temp',
                        dataIndex: 'health.vending_temperature',
                        width: 70,
                        renderer: function(value, meta, record){
                            if(!check_data_online(record)) return '';
                            const val = parseInt(val) || 0;
                            if(val >= 255 || val <= -255) return '<i class="x-fa fa-times x-color-red">'
                            return value ? `${value} &deg;C` : '<i class="x-fa fa-times x-color-red">';
                        }
                    }
                ]
            },
            {
                text: 'Raspi Summary',
                columns: [
                    {
                        text: 'CPU',
                        dataIndex: 'health.pi_cpu_usage',
                        sortable: false,
                        align: 'center',
                        width: 80,
                        renderer: pi_percentage()
                        // xtype: 'widgetcolumn',
                        // widget:{
                        //     xtype: 'progressbarwidget',
                        //     textTpl: '{percent:number("0")}%',
                        // }
                    },
                    {
                        text: 'Disk',
                        dataIndex: 'health.pi_storage',
                        sortable: false,
                        align: 'center',
                        width: 80,
                        renderer: pi_percentage(),
                        // xtype: 'widgetcolumn',
                        // widget:{
                        //     xtype: 'progressbarwidget',
                        //     textTpl: '{percent:number("0")}%',
                        // }
                    },
                    {
                        text: 'CPU Temp',
                        dataIndex: 'health.pi_cpu_temperature',
                        sortable: false,
                        align: 'center',
                        width: 90,
                        renderer: pi_percentage('&deg;C'),
                        // xtype: 'widgetcolumn',
                        // widget:{
                        //     xtype: 'progress',
                        //     textTpl: '{percent} &deg;C',
                        // }
                    }
                ]
            },
            {
                type: 'Sales Summary',
                columns: [
                    {
                        text: 'Today (Count)',
                        dataIndex: 'today_sale.total_qty',
                        width: 130
                    },
                    {
                        text: 'Today (Sale)',
                        dataIndex: 'today_sale.total_amount',
                        width: 130,
                        renderer: function(value, meta, record ){
                            Ext.util.Format.setConfig({thousandSeperator: '.'})
                            return Ext.util.Format.currency(value, 'Rp', 0, false, ' ')
                        }
                    },
                    {
                        text: 'Yesterday (Sale)',
                        dataIndex: 'yesterday_sale',
                        width: 150,
                        renderer: function(value, meta, record ){
                            Ext.util.Format.setConfig({thousandSeperator: '.'})
                            return Ext.util.Format.currency(value, 'Rp', 0, false, ' ')
                        }
                    },
                    {
                        text: 'Last Sale At',
                        dataIndex: 'last_sale',
                        xtype: 'datecolumn',
                        format: 'd/m/Y h:i A',
                        width: 150
                    }
                ]
            },
            {
                xtype: 'actioncolumn',
                items: [
                    {
                        iconCls: 'x-fa fa-eye x-color-green',
                        handler: function(view, rIndex, cIndex, item, e, record){
                            const page = window.open(`http://${record.get('ip_add')}:3001/management`, '_blank');
                            page.focus();
                        }
                    }
                ]
            }
        ]
    },
    listeners: {
        afterrender: function(){
            require('../../channels/health_channel')
        }
    }
})