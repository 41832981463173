Ext.define('Admin.view.location.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminlocationform',
    width: 350,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    items:[
        {
            name: 'location[name]',
            fieldLabel: 'Name'
        },
        {
            name: 'location[postalcode]',
            fieldLabel: 'Postal Code',
            allowBlank: true,
        },
        {
            name: 'location[address]',
            fieldLabel: 'Address',
            xtype: 'textarea'
        },
        {
            xtype: 'checkboxfield',
            fieldLabel: 'Is Active?',
            name: 'location[is_active]',
            uncheckedValue: false,
            value: true
        }
    ]
})