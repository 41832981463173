Ext.define('Admin.view.banner.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminbannerform',
    width: 350,
    defaults: {
        xtype: 'textfield',
        anchor:'100%',
        allowBlank: false
    },
    items:[
        {
            name: 'banner[name]',
            fieldLabel: 'Name'
        },
        {
            xtype: 'tagfield',
            name: 'banner[machine_banners_attributes][][machine_id]',
            fieldLabel: 'Machines',
            allowBlank: true,
            displayField: 'name',
            valueField: 'id',
            store: {
                type: 'adminstore',
                url: Routes.option_machines_path({all: true})
            },
            queryMode: 'local',
            recordKey: 'machine_ids',
            listeners: {
                select: function(tag, newValue){
                    const has_all = newValue.filter(x => x.id == 'all').length > 0
                    newValue = newValue.filter(x => x.id != 'all')
                    let allValue = tag.getStore().getRange().filter(x => x.id != 'all');
                    if(has_all){
                        if( newValue.length == allValue.length)
                            allValue = [];
                        
                        tag.setValue(allValue)
                    }
                }
            }
        },
        {
            name: 'banner[valid_from]',
            fieldLabel: 'Valid From',
            xtype: 'datetimefield', 
            format:'d/m/Y H:i',
            submitFormat: 'C'
        },
        {
            name: 'banner[valid_to]',
            fieldLabel: 'Valid To',
            xtype: 'datetimefield', 
            format:'d/m/Y H:i',
            submitFormat: 'C'
        },
        {
            xtype: 'filefield',
            fieldLabel: 'Images',
            name: 'banner[images][]',
            multiple: true,
            allowBlank: true
        }
    ]
})