Ext.define('Admin.view.machine.code.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'machinecode',
    xtype: 'machinecode',
    title: 'Machine Unique Codes Management',
    url: 'machine_codes_path',
    form: 'machinecodeform',
    disableConfig:{
        disableIsActiveColumn: true,
        disable: {
            create: true
        }
    },
    columns: [
        {
            dataIndex: 'code',
            text: 'Unique Code',
            width: 200
        },
        {
            dataIndex: 'machine_name',
            text: 'Machine'
        }
    ]
})