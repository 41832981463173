Ext.define('Admin.view.prices.Grid',{
    extend: 'Admin.view.template.Grid',
    itemId: 'adminprices',
    xtype: 'adminprice',
    title: 'Price Class Management',
    url: 'prices_path',
    form: 'adminpriceform',
    columns: [
        {
            dataIndex: 'name',
            text: 'Name'
        }
    ],
    customActionColumns: [
        {
            position: 0,
            iconCls: 'x-fa fa-clone x-btn-col-icon-info',
            tooltip: 'Copy Price',
            handler: function(grid, rowIndex, colIndex, item, e, record, row){
                Ext.Msg.confirm(`Copy ${record.get('name')}`,`Are you sure want to copy "${record.get('name')}"?`, function(ans){
                    if(ans == "yes"){
                        grid.mask('Copying...');
                        Ext.Ajax.request({
                            method: 'get',
                            url: Routes.duplicate_price_path(record.get('id')),
                            callback: function(opts, success, status){
                                const responseJSON = Ext.JSON.decode(status.responseText);
                                notify(responseJSON.message);
                                grid.getStore().reload();
                                grid.unmask();
                            }
                        })
                    }
                })
            }
        }
    ]
})