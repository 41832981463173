Ext.Loader.setPath('Ext.ux', '/extjs/packages/ux/classic/src/')
Ext.require('Ext.ux.form.SearchField');
Ext.require('Ext.ux.desktop.Video');
require('../bootstrap');
require(`../components/devise`);
require('../components/templates');
require('../components/entrance');
require('../components/menus');
require('../components/layouts');
require('../components/admintemplates');
require('../components/products');
require('../components/users');
require('../components/locations');
require('../components/roles');
require('../components/machines');
require('../components/prices');
require('../components/machine_codes');
require('../components/machine_configs');
require('../components/sales');
require('../components/machine_status');
require('../components/machine_stocks');
require('../components/banners');
require('../components/zones');
require('../components/folders');