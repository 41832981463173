Ext.define('Admin.view.product.Grid',{
    extend: 'Admin.view.template.Grid',
    xtype: 'adminproduct',
    title: 'Product Management',
    url: 'products_path',
    form: 'adminproductform',
    columns: [
        {
            dataIndex: 'thumb',
            renderer: function(value){
                return `<img src="${value}" class="product-image-cell" />`
            }
        },
        {
            dataIndex: 'name',
            text: 'Name',
            width: 250
        },
        {
            dataIndex: 'description',
            text: 'Description',
            width: 300
        },
        {
            dataIndex: 'weight',
            text: 'Weight (g)',
            width: 100
        }
    ]
})