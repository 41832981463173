const momentjs = require('moment');
Ext.define('Admin.view.machine.Form',{
    extend: 'Admin.view.template.Form',
    xtype: 'adminmachineform',
    width: 600,
    maxHeight: '100%',
    bodyPadding: 0,
    scrollable: true,
    defaultAlign: 't-t',
    initComponent: function(){
        const me=this;
        const record = me.record;
        Ext.apply(me, {
            xtype: 'container',
            layout: 'vbox',
            defaults:{
                width: '100%',
                collapsed: true,
                collapsible: true
                // width: '32%'
            },
            items:[
                {
                    xtype: 'panel',
                    bodyPadding: 5,
                    collapsible: false,
                    collapsed: false,
                    // width: '30%',
                    defaults: {
                        xtype: 'textfield',
                        anchor:'100%',
                        allowBlank: false,
                        width: '100%'
                    },
                    items:[
                        {
                            name: 'machine[name]',
                            fieldLabel: 'Name',
                            editable: !record
                        },
                        {
                            name: 'machine[location_id]',
                            fieldLabel: 'Location',
                            xtype: 'combobox',
                            displayField: 'name',
                            valueField: 'id',
                            store: {type: 'adminstore', url: Routes.option_locations_path()},
                            forceSelection: true,
                            queryMode: 'local',
                            triggerAction: 'all'
                        },
                        {
                            name: 'machine[zone_id]',
                            fieldLabel: 'Zone',
                            xtype: 'combobox',
                            displayField: 'name',
                            valueField: 'id',
                            store: {type: 'adminstore', url: Routes.option_zones_path()},
                            forceSelection: true,
                            queryMode: 'local',
                            triggerAction: 'all'
                        },
                        {
                            name: 'machine[user_id]',
                            fieldLabel: 'Driver',
                            xtype: 'combobox',
                            displayField: 'name',
                            valueField: 'id',
                            store: {type: 'adminstore', url: Routes.option_users_users_path()},
                            forceSelection: true,
                            queryMode: 'local',
                            triggerAction: 'all'
                        },
                        {
                            name: 'machine[ip_add]',
                            fieldLabel: 'IP Address',
                            value: '0.0.0.0'
                        },
                        {
                            name: 'machine[description]',
                            fieldLabel: 'Remarks',
                            xtype: 'textarea',
                            allowBlank: true
                        },
                        {
                            name: 'machine[abode]',
                            fieldLabel: 'Environment',
                            xtype: 'combobox',
                            store: [ ['development','Development'], ['production','Production'] ],
                            value: 'development',
                            editable: false
                        },
                        {
                            xtype: 'checkboxfield',
                            fieldLabel: 'Is Active?',
                            name: 'machine[is_active]',
                            uncheckedValue: false,
                            value: true
                        }
                    ]
                },
                {
                    xtype: 'adminmachinetemplate',
                    parentForm: me,
                    scrollable: true,
                    itemId: 'machine-template-grid',
                    disableConfig: { disableAll: true},
                    title: 'Templates Schedule',
                    preventHeader: false,
                    url: {
                        path: 'template_machine_path',
                        id: record ? record.get('id') : 0
                    },
                    selModel: 'cellmodel',
                    plugins: {
                       ptype: 'cellediting',
                       clicksToEdit: 2
                    }
                     
                },
                {
                    xtype: 'adminmachineprice',
                    parentForm: me,
                    scrollable: true,
                    itemId: 'machine-price-grid',
                    disableConfig: { disableAll: true},
                    title: 'Prices Schedule',
                    preventHeader: false,
                    url: {
                        path: 'price_machine_path',
                        id: record ? record.get('id') : 0
                    },
                    selModel: 'cellmodel',
                    plugins: {
                       ptype: 'cellediting',
                       clicksToEdit: 2
                    }
                }
            ]
        })
        me.callParent(arguments)
    },
    beforeSubmit: function(){
        const me = this;
        this.setParams({...this.getTemplates(), ...this.getPrices()})
    },
    getTemplates: function(){
        const self=this;
        const store = self.down('#machine-template-grid').getStore().getData();
        const count = store.count();
        let a = 0;
        const params = {};
        var has_error = false;
        while(count > a){
            const record = store.getAt(a).getData();
            console.log(!record.template_id , !record.valid_from , !record.valid_to)
            if( !record.template_id || !record.valid_from || !record.valid_to ){
                has_error = true
            }
            var valid_to = record.valid_to ? self.validDateFormat(record.valid_to) : record.valid_to
            var valid_from= record.valid_from ? self.validDateFormat(record.valid_from) : record.valid_from

            if(typeof record.id === 'number')  params[`machine[templates_attributes][${a}][id]`] = record.id
            params[`machine[templates_attributes][${a}][template_id]`] = record.template_id;
            params[`machine[templates_attributes][${a}][valid_from]`] = valid_from;
            params[`machine[templates_attributes][${a}][valid_to]`] = valid_to;
            a++;
        }
        self.setSubmitError(has_error);
        if( has_error ) notify('Template Schedule has error.', false, {position: 'center'});
        return params;
    },
    validDateFormat: function(date){
        valid = new Date(date).toISOString();
        valid = valid.substr(0, valid.lastIndexOf(":")+1) + "59.999999Z"
        return valid;
    },
    getPrices: function(){
        const self=this;
        const store = self.down('#machine-price-grid').getStore().getData();
        const count = store.count();
        let a = 0;
        const params = {};
        var has_error = false;
        while(count > a){
            const record = store.getAt(a).getData();
            if( !record.price_id || !record.valid_from || !record.valid_to ){
                has_error = true
            }
            
            var valid_to = record.valid_to ? self.validDateFormat(record.valid_to) : record.valid_to
            var valid_from= record.valid_from ? self.validDateFormat(record.valid_from) : record.valid_from

            if(typeof record.id === 'number')  params[`machine[prices_attributes][${a}][id]`] = record.id
            params[`machine[prices_attributes][${a}][price_id]`] = record.price_id;
            params[`machine[prices_attributes][${a}][valid_from]`] = valid_from;
            params[`machine[prices_attributes][${a}][valid_to]`] = valid_to;
            a++;
        }
        self.setSubmitError(has_error);
        if( has_error ) notify('Price Schedule has error.', false, {position: 'center'});
        return params;
    },
    validateDate: function(from, to){
        from = momentjs(from);
        to = momentjs(to);
        // to.diff(from,) 
        this.hasSubmitError = true;
    },
    customListeners: {
        resize: function(){
            
        }
    }
})